/* Loader styles */

ul, ol {
    padding-left: 20px;
}
.preloader-spinner {
    width:32px;
    height:32px;
    animation: preloader-spinner .5s linear infinite;
}
.preloader-spinner-icon {
    width: 32px;
    height: 32px;
    stroke: #ccc;
    stroke-width: 3px;
    stroke-dasharray: 314%, 314%;
    animation: preloader-spinner-icon-anim 1s linear infinite alternate;
}

@keyframes preloader-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader-spinner-icon-anim {
    0% {
        stroke-dasharray: 0%, 314%;
    }

    100% {
        stroke-dasharray: 314%, 314%;
    }
}

